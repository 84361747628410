
<template>
	<div class="s-manage-teacher-attendance-record-wrapper">
		<!-- 教师考勤 > 考勤规则 -->
		<div>
			<tabbed-page
				v-show='showTab'
				:tabList="tabList"
				@changeTabs="handlerChangeTabs"
			>
			</tabbed-page>
			<component
				:is="components[idx]" :checkingsInId="checkingsInId"
				@checkingsInIdChange="checkingsInIdChange" @expandManagement="expandManagement"
				@expandFilterUpdateManagement="expandFilterUpdateManagement" @editHoliday="editHoliday"
				@setPageInitTabs="setPageInitTabs" @addHoliday="addHoliday" @cancelAddHoliday="cancelAddHoliday"
			/>
		</div>
	</div>
</template>

<script>
	import {
		Error,
		Loading,
		TableData,
		Pagination,
		DialogWrapper
	} from "common-local"
	import OperationBtn from './AttendanceDetailsTest/OperationBtn.vue';
	import TabbedPage from './Sub/TabbedPage/index.vue'
	export default {
		name: "CheckAttendanceManager",
		components: {
			Error,
			Loading,
			TableData,
			Pagination,
			DialogWrapper,
			ExpandFilter:() => import(/* webpackChunkName:'ExpandFilter' */"./AttendanceDetailsTest/ExpandFilter.vue"),
			ExpandFilterUpdate:() => import(/* webpackChunkName:'ExpandFilterUpdate' */"./AttendanceDetailsTest/VirtulaList/ExpandFilterUpdate.vue"),
			OperationBtn,
			HolidayArrangement: () => import(/* webpackChunkName:'ExpandFilterUpdate' */"./AttendanceDetailsTest/HolidayArrangement.vue"),
			AddHolidayArrangement:() => import(/* webpackChunkName:'AddHolidayArrangement' */"./AttendanceDetailsTest/VirtulaList/AddHolidayArrangement.vue"),
			TabbedPage
		},
		data() {
			return {
				components: ['ExpandFilter', 'HolidayArrangement', 'ExpandFilterUpdate', 'AddHolidayArrangement'],
				idx: 0,
				checkingsInId: undefined,
				// 功能组件
				tabList: [
					{
						id: "first",
						title: "考勤规则",
						com: 'ExpandFilter',
						sel: true
						// vHasPermi: ["oaForm:all"],
					},
					{
						id: "second",
						title: "放假安排",
						com: 'Holidayrrangement',
						sel: false,
						vHasPermi: ["furloughSettings"],
					}
				],
				showTab: true
			}
		},
		computed: {

		},
		created() {

		},
		mounted() {

		},
		methods: {
			checkingsInIdChange(id) {
				this.checkingsInId = id
			},
			expandManagement() {
				this.idx = 0;
				this.showTab = true;
			},
			expandFilterUpdateManagement() {
				this.showTab = false;
				this.$eventDispatch('setGlobalPageType', 'list')
				this.idx = 2;
			},
			setPageInitTabs() {
				this.showTab = true;
				this.$eventDispatch('setGlobalPageType', 'list')
				this.idx = 0;
				this.tabList.forEach((item, index) => {
					index == 0 && (item.sel = true)
					index != 0 && (item.sel = false)
				})
			},
			addHoliday() {//放假安排-添加
				this.checkingsInId = '';
				this.showTab = false;
				this.$eventDispatch('setGlobalPageType', 'list')
				this.idx = 3;
			},
			editHoliday() {//放假安排-编辑
				this.showTab = false;
				this.$eventDispatch('setGlobalPageType', 'list')
				this.idx = 3;
			},
			cancelAddHoliday() {//添加放假安排页面 点击返回或者取消
				this.showTab = true;
				this.idx = 1;
			},
			handlerChangeTabs(data) {
				this.tabList.forEach((item, index) => {
					if (item.id === data.id) {
						this.$set(item, 'sel', true)
						this.idx = index;
					} else {
						this.$set(item, 'sel', false)
					}
				})
				
			},
		}
	}
</script>

<style lang="scss" scoped>
	.s-manage-teacher-attendance-record-wrapper {
		margin-right: 10px;
		box-sizing: border-box;
		border-radius: 6px;
	}
</style>

<template>
    <div class="des">
        <img class="des-images" :src="desImg" alt="des">
        当前页面可以勾选漏签人员或者取消错选人员
    </div>
</template>
<script>
export default {
    name: "Des",
    computed: {
        desImg () {
            return require('@/assets/images/tips.png')
        }
    }
}
</script>
<style lang="scss" scoped>
    .des {
        height: 48px;
        line-height: 48px;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2B2F33;
        margin-left: 20px;
        .des-images {
            width: 14px;
            height: 14px;
            margin-right: 6px;
        }
    }
</style>

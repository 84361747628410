<template>
    <div class="back-home-view">
        <!-- <Back /> -->
        <global-page-back
            detailPageTitle=""
            @handlerGlobalType="handleBack"
        >
            <template #globalPageBackTabs>
                <div style="display:flex;">
                    <Des  v-if="type == 'edit'" />
                    <stu-info :stuInfo="stuInfo"></stu-info>
                </div>
            </template>
        </global-page-back>
       
    </div>
</template>
<script>
import Back from './Back.vue';
import Des from './Des.vue';
import StuInfo from './StuInfo.vue';
import GlobalPageBack from "../../../Sub/GlobalPageBack/index.vue";
export default {
    name: "BackHome",
    props: {
        stuInfo: Object,
        type: String,
    },
    components: {
        Back,
        Des,
        StuInfo,
        GlobalPageBack
    },
    methods:{
        handleBack(){
            this.$eventDispatch('setGlobalPageType', 'list')
            this.$eventDispatch('back')
        }
    }
}
</script>
<style lang="scss" scoped>
    .back-home-view {
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        background-color: #FFFFFF;
        border-radius: 4px;
        overflow: hidden;
        margin-bottom: 10px;
    }
    .stu-info{
        margin-left: 16px !important;
        background-color: #FFF;
        display: flex;
        align-items: center;
    }
</style>

<template>
    <div class="stu-info">
        <span>日期：{{ stuInfo.checkDate }}</span>
        <span style="padding: 0 20px;">年级：{{ stuInfo.gradeName }}</span>
        <span>班级：{{ stuInfo.className }}</span>
    </div>
</template>
<script>
export default {
    name: "StuInfo",
    props: {
        stuInfo: Object,
    },
}
</script>
<style lang="scss" scoped>
    .stu-info {
        box-sizing: border-box;
        padding: 11px 18px;
        background-color: #F7F7F7;
        font-size: 12px;
        border-radius: 4px;
        margin-left: 24px;
    }
</style>

<template>
    <div class="back" @click="back">
        <img class="back-img" :src="backImg" alt="返回"> 返回
    </div>
</template>
<script>
export default {
    name: "Back",
    computed: {
        backImg () {
            return require('@/assets/images/back3.png');
        }
    },
    methods: {
        back () {
            this.$eventDispatch('back')
        }
    }
}
</script>
<style lang="scss" scoped>
    .back {
        height: 14px;
        line-height: 14px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2B2F33;
        border-right: 1px solid #E1E3E6;
        padding-right: 20px;
        cursor: pointer;
        .back-img {
            width: 10px;
            margin-right: 8px;
        }
    }
</style>
